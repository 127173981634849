"use client";

import Home from "@/components/Home";

export default function Component() {
  return (
    <div className="pc-container bg-black min-h-screen">
      <div className="mb-20">
        <Home />
      </div>
    </div>
  );
}
