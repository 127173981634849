"use client";

import { NotificationAPI, NotificationRequest } from "@/restapi/notification";
import APP_CONSTANTS from "@/constants/AppConstants";
import useSWR, { mutate } from "swr";

export const usePostScoringHistory = () => {
  const submit = async (request: NotificationRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const res = await NotificationAPI.postScoringHistory(request);

      // キャッシュを更新
      mutate(`notify-scoring-history-${JSON.stringify(request)}`, res, false);

      return res;
    } catch (err) {
      console.error("Failed to submit", err);
      throw err; // エラー処理
    }
  };

  return {
    submit,
  };
};

export const useNotifyPostNewPostedNumOfNotify = () => {
  const submit = async (request: NotificationRequest) => {
    try {
      // APIにPOSTリクエストを送信してデータを取得
      const res = await NotificationAPI.postNewPostedImage(request);

      // キャッシュを更新
      mutate(`notify-new-posted-${JSON.stringify(request)}`, res, false);

      return res;
    } catch (err) {
      console.error("Failed to submit", err);
      throw err; // エラー処理
    }
  };

  return {
    submit,
  };
};

export const useNotifyGetScoringHistory = (userId: string) => {
  const { data: item, error } = useSWR(
    userId ? `notify-scoring-history-${userId}` : null,
    () => NotificationAPI.getScoringHistory(userId),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export const useNotifyGetNewPostedImage = (userId: string) => {
  const { data: item, error } = useSWR(
    userId ? `notify-new-posted-${userId}` : null,
    () => NotificationAPI.getNewPostedImage(userId),
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME }
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export const useNotifyGetReadScoringHistory = (userId: string) => {
  const { data: item, error } = useSWR(
    userId ? `notify-scoring-history-read-${userId}` : null,
    () => NotificationAPI.readScoringHistory(userId),
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};

export const useNotifyGetReadNewPostedImage = (userId: string) => {
  const { data: item, error } = useSWR(
    userId ? `notify-new-posted-read-${userId}` : null,
    () => NotificationAPI.readNewPostedImage(userId),
  );

  return {
    item,
    isLoading: !error && !item,
    isError: error,
  };
};
