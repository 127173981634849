import { axios } from "@/lib/axios";
import { AxiosResponse } from "axios";
import { BackendURLContantas } from "@/constants/URLConstants";

export type NotificationEvent = {
  count: number;
  userUuid: string;
};

export type NotficationEventResponse = {
  notifications: NotificationEvent[] | undefined;
};

export type NotificationRequest = {
  userUuid: string;
  fromUserUuid: string;
};

export type NotificationPostResponse = {
  message: string;
};

export type NotificationGetResponse = {
  count: number;
};

export type NotificationReadGetResponse = {
  message: string;
};

export const NotificationAPI = {
  postNewPostedImage: async (request: NotificationRequest) => {
    const { data } = await axios.post<
      NotificationRequest,
      AxiosResponse<NotificationPostResponse>
    >(`${BackendURLContantas.NOTIFICATION.NEW_POSTED_IMAGE}`, request);

    return data;
  },
  postScoringHistory: async (request: NotificationRequest) => {
    const { data } = await axios.post<
      NotificationRequest,
      AxiosResponse<NotificationPostResponse>
    >(`${BackendURLContantas.NOTIFICATION.SCORING_HISTORY}`, request);

    return data;
  },
  getNewPostedImage: async (userId: string) => {
    const { data } = await axios.get<
      NotificationRequest,
      AxiosResponse<NotificationGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.NEW_POSTED_IMAGE.PATH_NAME}/${userId}`
    );

    return data;
  },
  getScoringHistory: async (userId: string) => {
    const { data } = await axios.get<
      NotificationRequest,
      AxiosResponse<NotificationGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.SCORING_HISTORY.PATH_NAME}/${userId}`
    );

    return data;
  },

  readNewPostedImage: async (userId: string) => {
    const { data } = await axios.get<
      NotificationRequest,
      AxiosResponse<NotificationReadGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.NEW_POSTED_IMAGE.READ.PATH_NAME}/${userId}`
    );

    return data;
  },
  readScoringHistory: async (userId: string) => {
    const { data } = await axios.get<
      NotificationRequest,
      AxiosResponse<NotificationReadGetResponse>
    >(
      `${BackendURLContantas.NOTIFICATION.SCORING_HISTORY.READ.PATH_NAME}/${userId}`
    );

    return data;
  },
};
