"use client";

import React, { useLayoutEffect, useState } from "react";
import { useLatestImages } from "@/hooks/image/useLatestImages";
import { useSession } from "next-auth/react";
import { useRankingFollowingImages } from "@/hooks/image/ranking/useRankingFollowingImages";
import { useNotifyGetReadNewPostedImage } from "@/hooks/notification/useNotification";
import { ImageResponseData } from "@/restapi/image";
import { RANKING_TYPE } from "@/restapi/ranking";
import { useAppContext } from "@/context/AppContextProvider";
import FullScreenList from "./image/full-screen/FullScreenList";
import RankingList from "./image/ranking/RankingList";

export default function Home() {
  const [items, setItems] = useState<ImageResponseData[]>([]);
  const [rankingType, setRankingType] = useState(RANKING_TYPE.LATEST);
  const [imageId, setImageId] = useState("");
  const { data: session } = useSession();
  const user = session?.user;
  const userId = user?.id ?? "";
  const { setImageDisplayMode, imageDisplayMode } = useAppContext();

  // 通知をリセットして通知件数を0にする
  useNotifyGetReadNewPostedImage(userId);

  // ランキング画面に切り替わったタイミングで一回itemsの状態を初期状態する
  // フルスクリーンで表示されてたitemsが残っているため
  useLayoutEffect(() => {
    if (imageDisplayMode === "RankingMode") {
      setItems([]);
    }
  }, [imageDisplayMode]);

  const {
    items: followingItems,
    isError: followingError,
    isLoading: followingLoading,
    hasMore: followingHasMore,
    loadMore: followingGetMore,
    isValidating,
  } = useRankingFollowingImages({
    myUserId: userId,
  });

  const {
    items: latestItems,
    isError: latestError,
    isLoading: latestLoading,
    hasMore: latestHasMore,
    loadMore: latestGetMore,
  } = useLatestImages({ myUserId: userId });

  const clickLatest = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setRankingType(RANKING_TYPE.LATEST);
  };

  const clickFollowing = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setRankingType(RANKING_TYPE.FOLLOWING);
  };

  const clickSelectImage = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    imageId: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setImageId(imageId);

    switch (rankingType) {
      case RANKING_TYPE.LATEST:
        setItems(latestItems);
        break;
      default:
        setItems(followingItems);
        break;
    }
    setImageDisplayMode("FullScreenMode");
  };

  // if (followingLoading || latestLoading || isValidating) {
  //   return (
  //     <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
  //       <div className="mb-10 text-center">Loading...</div>
  //     </div>
  //   );
  // }

  if (followingError || latestError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-black text-red-600 p-4">
        <div className="mb-10 text-center">Error</div>
      </div>
    );
  }

  // 新着投稿全画面表示に切り替え
  if (
    imageDisplayMode === "FullScreenMode" &&
    rankingType === RANKING_TYPE.LATEST &&
    0 < items.length
  ) {
    return (
      <FullScreenList
        imageOrderCol={"timestamp"}
        orderBy={"desc"}
        images={items}
        selectedImageId={imageId}
        rankingType={rankingType}
      />
    );
    // フォロー中全画面表示に切り替え
  } else if (
    imageDisplayMode === "FullScreenMode" &&
    rankingType === RANKING_TYPE.FOLLOWING &&
    0 < items.length
  ) {
    return (
      <FullScreenList
        imageOrderCol={"timestamp"}
        orderBy={"desc"}
        images={items}
        selectedImageId={imageId}
        rankingType={rankingType}
      />
    );
  } else {
    return (
      <RankingList
        defaultValue={RANKING_TYPE.LATEST}
        rankingType={rankingType}
        clickLatest={clickLatest}
        clickFollowing={clickFollowing}
        followingItems={followingItems}
        latestItems={latestItems}
        clickSelectImage={clickSelectImage}
        latestGetMore={latestGetMore}
        latestHasMore={latestHasMore}
        followingGetMore={followingGetMore}
        followingHasMore={followingHasMore}
      />
    );
  }
}
