'use client';

import useSWRInfinite from "swr/infinite";
import { RankingResponse, RankingAPI } from "@/restapi/ranking";
import { ImageRankingRequest } from "@/restapi/image";
import APP_CONSTANTS from "@/constants/AppConstants";

const fetcher = async (
  key: string,
  page: number,
  request: ImageRankingRequest
): Promise<RankingResponse> => {
  const data: RankingResponse = await RankingAPI.latestImages(request, page);
  return data;
};

export const useLatestImages = (request: ImageRankingRequest) => {
  const getUrl = (index: number, previousPageData: RankingResponse | null): string | null => {
    if (previousPageData && !previousPageData.data.length) return null; // Stop when no more data
    return request.userId
      ? `user-latest-scored-images-${request.myUserId}-${request.userId}-?page=${index + 1}`
      : `latest-scored-images?page=${index + 1}`;
  };

  const fetcherWrapper = (key: string): Promise<RankingResponse> => {
    const page = parseInt(key.split("=")[1]); // Extract the page number
    return fetcher(key, page, request);
  };

  const { data, error, size, setSize, isValidating } = useSWRInfinite<RankingResponse>(
    getUrl,
    fetcherWrapper,
    { refreshInterval: APP_CONSTANTS.REFETCH_INTERVAL_TIME },
  );

  const isLoading = !data && !error;
  const hasMore = data ? data[data.length - 1].currentPage < data[data.length - 1].lastPage : false;
  const items = data ? data.flatMap((page) => page.data) : [];

  const loadMore = () => {
    if (!isValidating && hasMore) {
      setSize(size + 1);
    }
  };

  return {
    items,
    isLoading,
    isError: error,
    loadMore,
    hasMore,
  };
};